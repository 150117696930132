import { css } from 'glamor';
import theme from "../../theme";

/**************************/
/***** BUTTON STYLE *****/
/**************************/

var container = css({
    padding: theme.tags.padding,
    display: "inline-flex",
    justifyContent: "center",
});




var style = {
    container,
}


export default style;